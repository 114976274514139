<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <base-form
      id="tambah-data-distributor"
      :form="dataDistributor"
      :nullable="nullable"
      @submit="addMember"
      v-slot="{ errors, performValidate }"
    >
      <BaseCard title="Pencatatan Data Mitra Usaha">
        <div class="space-y-6 divide-y pb-2">
          <div>
            <product-modal
              :visible="visibleRegistrationProductModal"
              :requestParams="{ 'filter[is_for_registration]': true }"
              @close="visibleRegistrationProductModal = false"
              @click-product="onChangeRegistrationProduct"
            />
            <div class="flex sm:flex-row sm:gap-x-4">
              <div class="relative w-full">
                <base-input
                  v-model="dataDistributor.registration_product.code"
                  @change="onSearchRegistrationProductCode"
                  type="text"
                  placeholder="Pilih KIT Barang"
                  label="Kit Barang"
                  with-label
                  inset
                  required
                  fullwidth
                  :disabled="is_detail && !!dataDistributor.registration_product.id"
                />
                <button
                  v-if="!is_detail || !dataDistributor.registration_product.id"
                  @click="visibleRegistrationProductModal = true"
                  type="button"
                  class="absolute right-4 top-6 cursor-pointer text-gray-400"
                >
                  <Icon
                    class="h-5 w-5"
                    icon="heroicons:magnifying-glass-circle-20-solid"
                  />
                </button>
              </div>
            </div>
          </div>
          <template v-if="dataDistributor.registration_product.id || is_detail">
            <div>
              <div>
                <div v-if="!is_detail && dataDistributor.registration_product.category === 'DAFTAR ND'" class="w-full mt-4 flex sm:flex-row sm:gap-x-4">
                  <base-input
                    v-model="officeSearchForm.code"
                    type="text"
                    label="Kode ND"
                    placeholder="Masukkan kode ND"
                    inset
                    required
                    with-label
                    :disabled="!!officeSearchForm.id"
                    :shadow="false"
                    fullwidth
                  />
                  <base-input
                    v-model="officeSearchForm.pin"
                    type="password"
                    label="PIN"
                    placeholder="Masukkan PIN"
                    inset
                    required
                    with-label
                    :disabled="!!officeSearchForm.id"
                    :shadow="false"
                    fullwidth
                  />
                </div>
                <div v-if="canFillForm" class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div
                    v-if="is_detail"
                    class="field-inset-default relative w-full"
                  >
                    <label
                      for="no_distributor"
                      class="block text-xs font-bold text-gray-700"
                    >
                      {{
                        getMember?.data?.attributes?.code ==
                        getMember?.data?.attributes?.temp_code
                          ? 'No Sementara'
                          : 'Nomor Tetap / Sementara'
                      }}
                    </label>
                    <input
                      disabled
                      :value="getMemberCode()"
                      @change="changeMitraCode"
                      type="text"
                      name="no_distributor"
                      id="no_distributor"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Nomor Tetap / Sementara"
                    />
                  </div>
                  <div class="w-full h-full">
                    <base-input
                      v-model="dataDistributor.nama_distributor"
                      type="text"
                      name="nama_distributor"
                      id="nama_distributor"
                      label="Mitra Usaha"
                      placeholder="Masukkan nama lengkap"
                      inset
                      required
                      with-label
                      :shadow="false"
                      :invalid="errors.nama_distributor"
                      :message="errors.nama_distributor"
                      fullwidth
                    />
                  </div>
                </div>
              </div>
            </div>
            
            <template v-if="canFillForm">
              <div class="pt-6">
                <div class="flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.no_nik"
                      type="text"
                      name="no_nik"
                      id="no_nik"
                      placeholder="Masukkan nomor identitas"
                      label="No. KTP/Identitas"
                      with-label
                      inset
                      required
                      :invalid="errors.no_nik"
                      :message="errors.no_nik"
                      fullwidth
                    />
                  </div>
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.email"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Masukkan email anda"
                      label="Email"
                      with-label
                      inset
                      required
                      :invalid="errors.email"
                      :message="errors.email"
                      fullwidth
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.no_hp"
                      type="text"
                      name="no_hp"
                      id="no_hp"
                      placeholder="Masukkan nomor hp"
                      label="No. HP"
                      with-label
                      inset
                      required
                      :invalid="errors.no_hp"
                      :message="errors.no_hp"
                      fullwidth
                    />
                  </div>
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.tanggal_lahir"
                      type="date"
                      max="9999-12-31"
                      name="tanggal_lahir"
                      id="tanggal_lahir"
                      placeholder="Masukkan nomor identitas"
                      label="Tanggal Lahir"
                      with-label
                      inset
                      required
                      :invalid="errors.tanggal_lahir"
                      :message="errors.tanggal_lahir"
                      fullwidth
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      label="Pendidikan Terakhir"
                      inset
                      with-label
                      :invalid="errors.education_id"
                      :message="errors.education_id"
                      fullwidth
                    >
                      <base-select
                        custom-height="h-auto"
                        :options="selectEducationOptions"
                        id="education_id"
                        inset
                        v-model="dataDistributor.education_id"
                        expand
                      />
                    </base-input>
                  </div>
                  <div class="w-full">
                    <base-input
                      label="Pekerjaan Terakhir"
                      inset
                      with-label
                      :invalid="errors.profession_id"
                      :message="errors.profession_id"
                      fullwidth
                    >
                      <base-select
                        custom-height="h-auto"
                        :options="selectProfessionOptions"
                        id="profession_id"
                        inset
                        v-model="dataDistributor.profession_id"
                        expand
                      />
                    </base-input>
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      :invalid="errors.jenis_kelamin"
                      :message="errors.jenis_kelamin"
                    >
                      <label
                        for="jenis_kelamin"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Jenis Kelamin
                      </label>
                      <div class="my-3 flex items-center space-x-8">
                        <div class="items-center text-sm text-gray-700">
                          <input
                            v-model="dataDistributor.jenis_kelamin"
                            type="radio"
                            name="jenis_kelamin"
                            id="jenis_kelamin"
                            value="male"
                            class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                            aria-labelledby="privacy-setting-1-label"
                            aria-describedby="privacy-setting-1-description"
                          />
                          Laki-laki
                        </div>
                        <div class="items-center text-sm text-gray-700">
                          <input
                            v-model="dataDistributor.jenis_kelamin"
                            type="radio"
                            name="jenis_kelamin"
                            value="female"
                            class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                            aria-labelledby="privacy-setting-1-label"
                            aria-describedby="privacy-setting-1-description"
                          />
                          Perempuan
                        </div>
                      </div>
                    </base-input>
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      :invalid="errors.jenis_kelamin"
                      :message="errors.jenis_kelamin"
                    >
                      <label
                        for="status_pernikahan"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Status Pernikahan
                      </label>
                      <div class="my-3 flex items-center space-x-8">
                        <div class="items-center text-sm text-gray-700">
                          <input
                            @change="maritalStats"
                            v-model="dataDistributor.status_pernikahan"
                            type="radio"
                            id="status_pernikahan"
                            name="status_pernikahan"
                            value="married"
                            class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                            aria-labelledby="privacy-setting-1-label"
                            aria-describedby="privacy-setting-1-description"
                          />
                          Menikah
                        </div>
                        <div class="items-center text-sm text-gray-700">
                          <input
                            @change="maritalStats"
                            v-model="dataDistributor.status_pernikahan"
                            type="radio"
                            name="status_pernikahan"
                            value="single"
                            class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                            aria-labelledby="privacy-setting-1-label"
                            aria-describedby="privacy-setting-1-description"
                          />
                          Belum Menikah
                        </div>
                      </div>
                    </base-input>
                  </div>
                </div>
                <div
                  class="mt-4 flex sm:flex-row sm:gap-x-4"
                  v-if="selectedMarital"
                >
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.nama_pasangan"
                      type="text"
                      name="nama_pasangan"
                      id="nama_pasangan"
                      placeholder="Masukkan nama pasangan"
                      label="Nama Pasangan"
                      with-label
                      inset
                      :invalid="errors.nama_pasangan"
                      :message="errors.nama_pasangan"
                      fullwidth
                    />
                  </div>
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.tanggal_pasangan"
                      type="date"
                      max="9999-12-31"
                      name="tanggal_pasangan"
                      id="tanggal_pasangan"
                      label="Tanggal Lahir"
                      with-label
                      inset
                      :invalid="errors.tanggal_pasangan"
                      :message="errors.tanggal_pasangan"
                      fullwidth
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.nama_pewaris"
                      type="text"
                      name="nama_pewaris"
                      id="nama_pewaris"
                      placeholder="Masukkan nama pewaris"
                      label="Nama Pewaris"
                      with-label
                      inset
                      :invalid="errors.nama_pewaris"
                      :message="errors.nama_pewaris"
                      fullwidth
                    />
                  </div>
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.tanggal_pewaris"
                      type="date"
                      max="9999-12-31"
                      name="tanggal_pewaris"
                      id="tanggal_pewaris"
                      label="Tanggal Lahir"
                      with-label
                      inset
                      :invalid="errors.tanggal_pewaris"
                      :message="errors.tanggal_pewaris"
                      fullwidth
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.dependents_number"
                      type="number"
                      name="dependents_number"
                      id="dependents_number"
                      placeholder="Masukkan Jumlah Anak + Tanggungan"
                      label="Jumlah Anak + Tanggungan"
                      with-label
                      inset
                      :invalid="errors.dependents_number"
                      :message="errors.dependents_number"
                      fullwidth
                    />
                  </div>
                  <div class="w-full">
                    <base-input
                      v-if="dataDistributor.status_pernikahan === 'married'"
                      label="Status Suami / Istri"
                      inset
                      id="spouse_employment_status"
                      with-label
                      :invalid="errors.spouse_employment_status"
                      :message="errors.spouse_employment_status"
                      fullwidth
                    >
                      <base-select
                        custom-height="h-auto"
                        :options="spouseEmploymentStatusOptions"
                        id="spouse_employment_status"
                        inset
                        v-model="dataDistributor.spouse_employment_status"
                        expand
                      />
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <label for="alamat">Alamat</label>
                <div class="flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.alamat.detail"
                      tag="textarea"
                      type="text"
                      name="detail"
                      id="alamat"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Nama Jalan, Nomor, RT/RW, Desa/Kelurahan"
                      label="Alamat"
                      with-label
                      inset
                      required
                      :invalid="errors.alamat.detail"
                      :message="errors.alamat.detail"
                      fullwidth
                      rows="3"
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      label="Provinsi"
                      inset
                      required
                      with-label
                      :invalid="errors.alamat.provinsi"
                      :message="errors.alamat.provinsi"
                      fullwidth
                    >
                      <select
                        v-model="dataDistributor.alamat.provinsi"
                        @change="changeProvince"
                        id="provinsi"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      >
                        <option :value="null">Pilih Provinsi</option>
                        <option
                          v-for="provinsi in getProvinces.data"
                          :key="provinsi.id"
                          :value="provinsi.id"
                        >
                          {{ provinsi.attributes.name }}
                        </option>
                      </select>
                    </base-input>
                  </div>
                  <div class="w-full">
                    <base-input
                      label="Kab/Kota"
                      inset
                      required
                      with-label
                      :invalid="errors.alamat.kabupaten"
                      :message="errors.alamat.kabupaten"
                      fullwidth
                    >
                      <select
                        v-model="dataDistributor.alamat.kabupaten"
                        @change="changeCity"
                        id="kabupaten"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      >
                        <option :value="null">Pilih Kabupaten</option>
                        <option
                          v-for="kab in getCities.data"
                          :key="kab.id"
                          :value="kab.id"
                        >
                          {{ kab.attributes.name }}
                        </option>
                      </select>
                    </base-input>
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                      <base-input
                      label="Kecamatan"
                      inset
                      required
                      with-label
                      :invalid="errors.alamat.kecamatan"
                      :message="errors.alamat.kecamatan"
                      fullwidth
                    >
                      <select
                        v-model="dataDistributor.alamat.kecamatan"
                        @change="changeDistrict"
                        id="kecamatan"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      >
                        <option :value="null">Pilih Kecamatan</option>
                        <option
                          v-for="kec in getDistricts.data"
                          :key="kec.id"
                          :value="kec.id"
                        >
                          {{ kec.attributes.name }}
                        </option>
                      </select>
                    </base-input>
                  </div>
                  <div class="w-full">
                    <base-input
                      label="Desa"
                      inset
                      required
                      with-label
                      :invalid="errors.alamat.desa"
                      :message="errors.alamat.desa"
                      fullwidth
                    >
                      <select
                        v-model="dataDistributor.alamat.desa"
                        id="desa"
                        class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      >
                        <option :value="null">Pilih desa</option>
                        <option
                          v-for="desa in getVillages.data"
                          :key="desa.id"
                          :value="desa.id"
                        >
                          {{ desa.attributes.name }}
                        </option>
                      </select>
                    </base-input>
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.alamat.kode_pos"
                      type="text"
                      name="kode_pos"
                      id="kode_pos"
                      placeholder="Kode Pos"
                      label="Kode Pos"
                      with-label
                      inset
                      required
                      :invalid="errors.alamat.kode_pos"
                      :message="errors.alamat.kode_pos"
                      fullwidth
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full bg-gray-100">
                    <label
                      for="area"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Area
                    </label>
                    <input
                      @change="changeArea"
                      type="text"
                      name="area"
                      id="area"
                      readonly
                      class="block w-full border-0 bg-gray-100 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Silakan Pilih Kabupaten/Kota"
                      v-model="dataDistributor.area"
                    />
                  </div>
                </div>
              </div>
              <div class="pt-6">
                <div>
                  <h4 class="font-medium text-gray-900">Data Bonus</h4>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="relative w-full">
                    <base-input
                      v-model="dataDistributor.office_bonus_pickup.code"
                      @blur="searchBonusPickup"
                      type="text"
                      name="office_bonus_pickup_code"
                      id="office_bonus_pickup_code"
                      placeholder="Tempat pengambilan bonus"
                      label="Tempat Pengambilan Bonus"
                      with-label
                      inset
                      required
                      fullwidth
                    />
                    <button
                      @click="toggleBonusOfficeModal"
                      type="button"
                      class="absolute right-4 top-6 cursor-pointer text-green-600"
                    >
                      <Icon
                        class="h-5 w-5"
                        icon="heroicons:magnifying-glass-circle-20-solid"
                      />
                    </button>
                  </div>
                  <base-input
                    v-model="dataDistributor.office_bonus_pickup.name"
                    disabled
                    type="text"
                    name="office_bonus"
                    id="office_bonus"
                    placeholder="Nama Stockist (otomatis terisi)"
                    label="Nama Stockist"
                    with-label
                    inset
                    fullwidth
                  />
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <label
                      for="is_kta_need_delivery"
                      class="block text-xs font-bold text-gray-700"
                    >
                      KTA
                    </label>
                    <div class="mt-3 flex items-center space-x-8">
                      <div class="items-center text-sm text-gray-700">
                        <input
                          v-model="dataDistributor.is_kta_need_delivery"
                          type="checkbox"
                          name="is_kta_need_delivery"
                          id="is_kta_need_delivery"
                          class="mr-2 h-4 w-4 rounded border-gray-300 text-gray-500 focus:ring-gray-500"
                          :class="[
                            is_detail ? 'cursor-not-allowed' : 'cursor-pointer',
                          ]"
                          aria-labelledby="privacy-setting-1-label"
                          aria-describedby="privacy-setting-1-description"
                          :disabled="is_detail"
                        />
                        <label for="is_kta_need_delivery"
                          >Dikirim langsung ke alamat mitra usaha</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-6">
                <div>
                  <h4 class="font-medium text-gray-900">Data Bank</h4>
                  <p class="text-sm text-gray-500">
                    Data ini digunakan untuk pencarian bonus
                  </p>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      label="Nama Bank"
                      name="nama_bank"
                      inset
                      with-label
                      :invalid="errors.bank_id"
                      :message="errors.bank_id"
                      fullwidth
                    >
                      <base-select
                        custom-height="h-auto"
                        :options="selectBankOptions"
                        inset
                        id="nama_bank"
                        v-model="dataDistributor.bank_id"
                        expand
                      />
                    </base-input>
                  </div>
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.bank.no_rekening"
                      type="text"
                      name="bank_no_rekening"
                      id="bank_no_rekening"
                      placeholder="Masukkan nomor rekening"
                      label="No. Rekening"
                      with-label
                      inset
                      :invalid="errors.bank.no_rekening"
                      :message="errors.bank.no_rekening"
                      fullwidth
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.bank.cabang_bank"
                      type="text"
                      name="bank_cabang_bank"
                      id="bank_cabang_bank"
                      placeholder="Masukkan cabang bank"
                      label="Cabang Bank"
                      with-label
                      inset
                      :invalid="errors.bank.cabang_bank"
                      :message="errors.bank.cabang_bank"
                      fullwidth
                    />
                  </div>
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.bank.nama_rekening"
                      type="text"
                      name="bank_nama_rekening"
                      id="bank_nama_rekening"
                      placeholder="Masukkan nama pemilik rekening (harus sama dengan pendaftar)"
                      label="Nama Rekening"
                      with-label
                      inset
                      :invalid="errors.bank.nama_rekening"
                      :message="errors.bank.nama_rekening"
                      fullwidth
                    />
                  </div>
                </div>
              </div>
              <div class="pt-6">
                <div>
                  <h4 class="font-medium text-gray-900">Data NPWP</h4>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.no_npwp"
                      type="text"
                      name="no_npwp"
                      id="no_npwp"
                      placeholder="Masukkan nomor npwp"
                      label="No. NPWP"
                      with-label
                      inset
                      :invalid="errors.no_npwp"
                      :message="errors.no_npwp"
                      fullwidth
                    />
                  </div>  
                  <div class="w-full">
                    <base-input
                      v-model="dataDistributor.nama_npwp"
                      type="text"
                      name="nama_npwp"
                      id="nama_npwp"
                      placeholder="Nama NPWP"
                      label="Nama NPWP"
                      with-label
                      inset
                      :invalid="errors.nama_npwp"
                      :message="errors.nama_npwp"
                      fullwidth
                    />
                  </div>  
                </div>
              </div>
              <div class="pt-6">
                <div>
                  <h4 class="font-medium text-gray-900">Data Referal</h4>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="relative w-full">
                    <base-input
                      v-model="dataDistributor.referal.no_upline"
                      @blur="searchReferal"
                      type="text"
                      name="referal_no_upline"
                      id="referal_no_upline"
                      placeholder="Masukkan kode referal"
                      label="Nomor Upline"
                      with-label
                      inset
                      required
                      :invalid="errors.referal.no_upline"
                      :message="errors.referal.no_upline"
                      fullwidth
                    />
                    <button
                      @click="toggleOfficeModal"
                      type="button"
                      class="absolute right-4 top-6 cursor-pointer text-green-600"
                    >
                      <Icon
                        class="h-5 w-5"
                        icon="heroicons:magnifying-glass-circle-20-solid"
                      />
                    </button>
                  </div>
                  <!-- <base-input
                      v-model="dataDistributor.referal.no_upline"
                      @blur="searchReferal"
                      type="text"
                      name="referal_no_upline"
                      id="referal_no_upline"
                      placeholder="Masukkan kode referal"
                      label="Nomor Upline"
                      with-label
                      inset
                      :invalid="errors.referal.no_upline"
                      :message="errors.referal.no_upline"
                      fullwidth
                    /> -->
                  <base-input
                    v-model="dataDistributor.referal.nama_upline"
                    disabled
                    type="text"
                    name="referal_nama_upline"
                    id="referal_nama_upline"
                    placeholder="Nama referal (otomatis terisi)"
                    label="Nama Upline"
                    with-label
                    inset
                    :invalid="errors.referal.nama_upline"
                    :message="errors.referal.nama_upline"
                    fullwidth
                  />
                </div>
              </div>
              <div></div>
            </template>
          </template>
        </div>

        <div v-if="is_detail || dataDistributor.registration_product.id" class="py-5">
          <div class="flex justify-between">
            <div class="flex items-center gap-x-2">
              <base-button
                v-if="is_detail && !getMember.data?.attributes?.is_nd && getMember.data?.attributes?.is_draft"
                @click="removeOffice"
                id="batal_product"
                type="button"
                color="danger"
              >
                <Icon class="h-5 w-5" icon="heroicons:trash" />
                Hapus
              </base-button>
            </div>
            <div class="flex">
              <template v-if="canFillForm">
                <template v-if="dataDistributor.registration_product.category === 'DAFTAR'">
                  <base-button
                    id="simpan_draft"
                    color="secondary"
                    v-if="!is_detail && !dataDistributor.is_active"
                    @click="saveAsDraft({ validate: performValidate })"
                    type="button"
                  >
                    Simpan sebagai draft
                  </base-button>
                  <div class="tooltip flex">
                    <base-button
                      v-if="!dataDistributor.is_active && !is_detail"
                      id="simpan_validasi"
                      type="submit"
                    >
                      Siap, Validasi
                    </base-button>
                  </div>
                </template>
                <div class="tooltip flex">
                  <base-button
                    v-if="getMember.data?.attributes.is_draft && (is_detail || dataDistributor.registration_product.category === 'DAFTAR ND')"
                    id="simpan_validasi"
                    @click="confirmation = true"
                    type="button"
                  >
                    Validasi Mitra
                  </base-button>
                </div>
              </template>
              <div class="tooltip flex" v-else>
                <base-button
                  id="check_mitra_nd"
                  :disabled="!officeSearchForm.code || !officeSearchForm.pin"
                  @click="onSearchOffice"
                  type="button"
                >
                  Lanjut
                </base-button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-xs text-gray-500"
          v-if="getMember.data?.meta && $route.params.id"
        >
          <p>
            Diupdate pada
            {{ dayjs(getMember.data.meta.updatedAt).format('LL LT') }} oleh
            {{ getMember.data.meta.updatedBy }}
          </p>
          <p>
            Dibuat pada
            {{ dayjs(getMember.data.meta.createdAt).format('LL LT') }} oleh
            {{ getMember.data.meta.createdBy }}
          </p>
        </div>
      </BaseCard>
    </base-form>

    <success-modal
      v-model="success"
      :title="successMessage"
      @close="
        () => {
          success = !success;
          $router.push('/admin/daftar-member');
        }
      "
      @finish="
        () => {
          success = !success;
          $router.push('/admin/daftar-member');
        }
      "
    />

    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="confirmation"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="validateMember">
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <!-- Heroicon name: outline/check -->
                  <svg
                    class="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Validasi
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Apakah anda yakin data yang dibuat sudah benar?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-4 gap-2 text-center sm:flex sm:flex-row">
                <base-button fullwidth type="submit"> Ya </base-button>
                <base-button
                  fullwidth
                  color="white"
                  @click="confirmation = !confirmation"
                  type="button"
                >
                  Kembali
                </base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <OfficeModal
      view-as="stockist"
      :is-visible="officeModalVisible"
      @onSearchKantor="(q) => onSearchRefferal('member', q)"
      @onPageChangeKantor="(page) => onChangePageRefferal('member', page)"
      @change-office="changeSelectedOffice"
      @closeKantorModal="() => (officeModalVisible = false)"
    />
    <OfficeModal
      title="Daftar Stockist"
      description="Berdasarkan data Stockist yang telah dibuat"
      search-placeholder="Cari kode / nama stockist"
      view-as="stockist"
      :table-column-labels="{
        code: 'Kode Stockist',
        name: 'Nama Stockist',
      }"
      :table-columns="{
        upline: false,
        address: false,
        phone: false,
      }"
      :is-visible="bonusOfficeModalVisible"
      @onSearchKantor="(q) => onSearchRefferal('stockist', q)"
      @onPageChangeKantor="(page) => onChangePageRefferal('stockist', page)"
      @change-office="changeSelectedBonusOffice"
      @closeKantorModal="() => (bonusOfficeModalVisible = false)"
    />
    <loading v-if="isLoading" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import BaseForm from '@/components/base/BaseForm.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import SuccessModal from '@/components/modal/success-modal.vue';
import dayjs from 'dayjs';
import OfficeModal from '@/components/office/office-modal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import ProductModal from '@/components/product/ProductModal.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  name: 'TambahMitraUsaha',
  components: {
    BaseForm,
    BaseInput,
    BaseSelect,
    SuccessModal,
    OfficeModal,
    BaseButton,
    ProductModal,
  },
  data() {
    return {
      is_detail: false,
      success: false,
      failed: false,
      officeModalVisible: false,
      bonusOfficeModalVisible: false,
      confirmation: false,
      selectedMarital: false,
      successMessage:
        'Data mitra berhasil disimpan, segera lakukan validasi pembayaran di halaman kasir > pembayaran',
      objectAddress: {
        data: [],
        meta: {
          page: {},
        },
      },
      member_office_category: null,
      dataDistributor: {
        id: null,
        nomor: null,
        nama_lengkap: null,
        nama_distributor: null,
        no_nik: null,
        email: null,
        no_hp: null,
        tanggal_lahir: null,
        education_id: null,
        profession_id: null,
        spouse_employment_status: null,
        jenis_kelamin: null,
        status_pernikahan: null,
        nama_pasangan: null,
        tanggal_pasangan: null,
        nama_pewaris: null,
        dependents_number: null,
        tanggal_pewaris: null,
        alamat: {
          detail: null,
          desa: null,
          kecamatan: null,
          kabupaten: null,
          provinsi: null,
          kode_pos: null,
        },
        area: null,
        bank_id: null,
        bank: {
          nama_bank: null,
          no_rekening: null,
          cabang_bank: null,
          nama_rekening: null,
        },
        no_npwp: null,
        nama_npwp: null,
        is_active: null,
        is_kta_need_delivery: false,
        referal: {
          no_upline: null,
          nama_upline: null,
        },
        office_bonus_pickup: {
          id: null,
          code: null,
          name: null,
        },
        registration_product: {
          id: null,
          code: null,
          category: null
        },
      },
      visibleRegistrationProductModal: false,
      loadingRegistrationProduct: false,
      loadingSeachOffice: false,
      officeSearchForm: {
        id: null,
        code: null,
        pin: null
      },
      loadingSaveAndValidate: false
    };
  },
  created() {
    this.loadBanks();

    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataDistributor.id = this.$route.params.id;
      this.loadMember(this.$route.params.id).then((response) => {
        this.setMember(response.data.data);
      });
    }
    this.fetchAreas({
      fields: {
        areas: 'code',
      },
    });
    this.fetchEducations({
      fields: {
        educations: 'name',
      },
    });
    this.fetchProfessions({
      fields: {
        professions: 'name',
      },
    });
    this.fetchProvinces({
      pageNumber: 1,
      fields: {
        provinces: 'name',
      },
    });
    this.loadBanks();
    this.member_office_category = this.getAvailableOfficeCategories.filter(
      function (el) {
        return el.attributes.name === 'Member';
      }
    );
    const handleEscape = () => {
      this.success =
        this.failed =
        this.openListProduct =
        this.openListSupplier =
          false;
      this.keywordProduct = this.keywordSupplier = '';
    };
    this.setEscape(handleEscape);

    this.dataDistributor.office_bonus_pickup = {
      id: this.me.office_id,
      code: this.me.office_code,
      name: this.me.name,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
      getAreas: 'areas/getAreas',
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getBanks: 'banks/getBanks',
      getMember: 'members/getMember',
      getOffices: 'simple_offices/getSimpleOffices',
      getError: 'members/getError',
      getEducations: 'educations/getEducations',
      getProfessions: 'professions/getProfessions',
      getOfficeCategories: 'office_categories/getOfficeCategories',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
      getOfficesLoading: 'offices/getLoading',
      getSimpleOfficesLoading: 'simple_offices/getLoading',
      getMemberLoading: 'members/getLoading',
    }),
    getSubtitle() {
      return this.getMember.data?.attributes?.updatedAt;
    },
    isLoading() {
      return (
        this.getOfficesLoading ||
        this.getSimpleOfficesLoading ||
        this.getMemberLoading ||
        this.loadingRegistrationProduct ||
        this.loadingSeachOffice ||
        this.loadingSaveAndValidate
      );
    },
    nullable: function () {
      return {
        id: null,
        nomor: null,
        nama_lengkap: null,
        is_active: null,
        referal: { nama_upline: null },
        bank: {
          nama_bank: null,
          no_rekening: null,
          cabang_bank: null,
          nama_rekening: null,
        },
        education_id: null,
        profession_id: null,
        jenis_kelamin: null,
        status_pernikahan: null,
        nama_pasangan: null,
        tanggal_pasangan: null,
        nama_pewaris: null,
        dependents_number: null,
        spouse_employment_status: null,
        bank_id: null,
        no_npwp: null,
        nama_npwp: null,
        tanggal_pewaris: null,
      };
    },
    selectBankOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Bank',
          value: null,
        },
        ...this.getBanks.data.map((bank) => ({
          key: bank.id,
          label: bank.attributes.name,
          value: bank.id,
        })),
      ];
    },
    selectEducationOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Pendidikan',
          value: null,
        },
        ...this.getEducations.data.map((education) => ({
          key: education.id,
          label: education.attributes.name,
          value: education.id,
        })),
      ];
    },
    selectProfessionOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Pekerjaan',
          value: null,
        },
        ...this.getProfessions.data.map((profession) => ({
          key: profession.id,
          label: profession.attributes.name,
          value: profession.id,
        })),
      ];
    },
    spouseEmploymentStatusOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Status Suami / Istri',
          value: null,
        },
        {
          key: 'Bekerja',
          label: 'Bekerja',
          value: 1,
        },
        {
          key: 'Tidak Bekerja',
          label: 'Tidak Bekerja',
          value: 0,
        },
      ];
    },
    selectRequiredProductOptions() {
      return [
        {
          key: 'all',
          label: 'Pilih KIT Barang',
          value: null,
        },
      ];
    },
    disabledValidation() {
      return false;
    },
    disabledDraft() {
      return false;
    },
    canFillForm() {
      return this.is_detail || this.officeSearchForm.id || this.dataDistributor.registration_product.category !== 'DAFTAR ND'
    }
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOfficesByCategory: 'offices/fetchOfficesByCategory',
      createAlert: 'alert/createAlert',
      fetchAreas: 'areas/fetchAreas',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchBanks: 'banks/fetchBanks',
      createMember: 'members/createMember',
      fetchMember: 'members/fetchMember',
      updateMember: 'members/updateMember',
      updateOffice: 'offices/updateOffice',
      fetchOffices: 'simple_offices/fetchSimpleOffices',
      fetchEducations: 'educations/fetchEducations',
      fetchProfessions: 'professions/fetchProfessions',
    }),
    onSearchRefferal(officeType, val) {
      this.loadOffices({
        'filter[office_category_id]': officeType === 'stockist' ? 4 : 5,
        'filter[search]': val,
        ...(officeType === 'member'
          ? {
              'filter[is_active]': true,
              'filter[is_draft]': false,
            }
          : {}),
      });
    },
    onChangePageRefferal(officeType, page) {
      const filter = {
        'filter[office_category_id]': officeType === 'stockist' ? 4 : 5,
        ...(officeType === 'member'
          ? {
              'filter[is_active]': true,
              'filter[is_draft]': false,
            }
          : {}),
      };

      this.loadOffices({
        ...page,
        ...filter,
      });
    },
    changeSelectedBonusOffice(office) {
      this.searchBonusPickup(
        this.getSingleIncluded(
          this.getOffices,
          office.relationships.office.data.id
        ).attributes.code
      );
      this.bonusOfficeModalVisible = false;
    },
    changeSelectedOffice(office) {
      this.searchReferal(
        this.getSingleIncluded(
          this.getOffices,
          office.relationships.office.data.id
        ).attributes.code
      );
      this.officeModalVisible = false;
    },
    async toggleBonusOfficeModal() {
      if (!this.bonusOfficeModalVisible) {
        await this.loadOffices({
          'filter[office_category_id]': 4,
        });
      }
      this.bonusOfficeModalVisible = !this.bonusOfficeModalVisible;
    },
    async toggleOfficeModal() {
      if (!this.officeModalVisible) {
        await this.loadOffices({
          'filter[office_category_id]': 5,
          'filter[is_active]': true,
          'filter[is_draft]': false,
        });
      }
      this.officeModalVisible = !this.officeModalVisible;
    },
    loadOffices(params = {}) {
      return this.fetchOffices({
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'office',
        'fields[offices]':
          'referred_by,referred_by_name,name,code,temp_code,uplines',
        ...params,
      });
    },
    loadBanks: async function () {
      await this.fetchBanks({
        'filter[is_mitra_bank]': true,
        fields: {
          banks: 'name',
        },
      });
    },
    loadMember: async function (id) {
      return await this.fetchMember({
        id,
        include:
          'bank,addresses,upline-relations,education,profession,area,bonus-office,registration-product',
        fields: {
          offices:
            'registration-product,address,bank_account_name,bank_account_number,is_nd,bank_branch_name,birthdate,code,temp_code,devisor_name,devisor_birthdate,email,is_active,ktp_number,marital_status,name,npwp_number,npwp_name,phone,postal_code,sex,spouse_name,spouse_birthdate,addresses,upline-relations,bank,area,education,profession,is_kta_need_delivery,dependents_number,is_draft,spouse_employment_status,bonus-office,updatedAt,createdAt',
          addresses: 'city_id,district_id,province_id,village_id',
          'upline-relations': 'code,relation_type',
          banks: 'code',
          educations: 'name',
          professions: 'name',
          areas: 'code',
        },
      });
    },
    changeArea(e) {
      this.dataDistributor.area =
        e.target.value < 0 || e.target.value > 4
          ? 0
          : isNaN(e.target.value)
          ? 0
          : Number(e.target.value);
    },
    maritalStats() {
      if (this.dataDistributor.status_pernikahan === 'married') {
        this.selectedMarital = true;
      } else {
        this.selectedMarital = false;
      }
    },
    clearData() {
      this.dataDistributor = {
        id: null,
        nomor: null,
        nama_lengkap: null,
        nama_distributor: null,
        no_nik: null,
        email: null,
        no_hp: null,
        tanggal_lahir: null,
        education_id: null,
        profession_id: null,
        spouse_employment_status: null,
        jenis_kelamin: null,
        status_pernikahan: null,
        nama_pasangan: null,
        tanggal_pasangan: null,
        nama_pewaris: null,
        dependents_number: null,
        tanggal_pewaris: null,
        alamat: {
          detail: null,
          desa: null,
          kecamatan: null,
          kabupaten: null,
          provinsi: null,
          kode_pos: null,
        },
        area: null,
        bank_id: null,
        bank: {
          nama_bank: null,
          no_rekening: null,
          cabang_bank: null,
          nama_rekening: null,
        },
        no_npwp: null,
        nama_npwp: null,
        is_active: null,
        is_kta_need_delivery: false,
        referal: {
          no_upline: null,
          nama_upline: null,
        },
        office_bonus_pickup: this.dataDistributor.office_bonus_pickup,
        registration_product: {
          id: null,
          code: null,
          category: null
        }
      };

      this.officeSearchForm.id = null
      this.officeSearchForm.code = null
      this.officeSearchForm.pin = null
    },
    setMember(data) {
      const bonusOffice = this.getMember.included.find(
        (it) => it.id == data.relationships['bonus-office'].data?.id
      );
      const address = this.getRelationships(
        this.getMember,
        data.relationships['addresses'].data?.[0]?.id
      );
      const registrationProduct = data.relationships['registration-product'].data ? this.getSingleIncluded(
        this.getMember,
        data.relationships['registration-product'].data.id
      ) : null;

      this.dataDistributor = {
        id: data.id,
        nomor: data.attributes.code,
        nama_distributor: data.attributes.name,
        email: data.attributes.email,
        nama_lengkap: data.attributes.name,
        no_nik: data.attributes.ktp_number,
        no_hp: data.attributes.phone,
        tanggal_lahir: dayjs(data.attributes.birthdate).format('YYYY-MM-DD'),
        jenis_kelamin: data.attributes.sex,
        status_pernikahan: data.attributes.marital_status,
        nama_pasangan: data.attributes.spouse_name,
        tanggal_pasangan: data.attributes.spouse_birthdate,
        nama_pewaris: data.attributes.devisor_name,
        dependents_number: data.attributes.dependents_number,
        tanggal_pewaris: dayjs(data.attributes.devisor_birthdate).format(
          'YYYY-MM-DD'
        ),
        no_npwp: data.attributes.npwp_number,
        nama_npwp: data.attributes.npwp_name,
        is_active: data.attributes.is_active,
        is_kta_need_delivery: data.attributes.is_kta_need_delivery,
        area: this.getRelationships(
          this.getMember,
          this.getMember.data.relationships.area?.data?.id
        )?.attributes.code,
        bank_id: this.getMember.data.relationships.bank?.data?.id ?? null,
        education_id: this.getMember.data.relationships.education?.data?.id ?? null,
        profession_id: this.getMember.data.relationships.profession?.data?.id ?? null,
        spouse_employment_status:
          this.getMember.data.attributes.spouse_employment_status,
        alamat: {
          detail: data.attributes.address,
          desa: address ? address.attributes.village_id : null,
          kecamatan: address ? address.attributes.district_id : null,
          kabupaten: address ? address.attributes.city_id : null,
          provinsi: address ? address.attributes.province_id : null,
          kode_pos: data.attributes.postal_code,
        },
        bank: {
          nama_bank: data.attributes.bank_name,
          no_rekening: data.attributes.bank_account_number,
          cabang_bank: data.attributes.bank_branch_name,
          nama_rekening: data.attributes.bank_account_name,
        },
        referal: {
          no_upline: this.getMember.included.find(
            (el) =>
              el.attributes.relation_type === 'referral' &&
              el.type === 'upline-relations'
          )?.attributes.code,
          nama_upline: null,
        },
        office_bonus_pickup: bonusOffice ? {
          id: bonusOffice.id,
          code: bonusOffice.attributes.code,
          name: bonusOffice.attributes.name,
        } : this.dataDistributor.office_bonus_pickup,
        registration_product: registrationProduct ? {
          id: registrationProduct.id,
          code: registrationProduct.attributes.code,
          category: registrationProduct.attributes.product_category_code
        } : this.dataDistributor.registration_product,
      };

      if (this.dataDistributor.alamat.provinsi) {
        this.fetchCities({
          province_id: this.dataDistributor.alamat.provinsi,
          pageNumber: 1,
        });
      }

      if (this.dataDistributor.alamat.kabupaten) {
        this.fetchDistricts({
          city_id: this.dataDistributor.alamat.kabupaten,
          pageNumber: 1,
          fields: {
            provinces: 'name',
          },
        });
      }

      if (this.dataDistributor.alamat.kecamatan) {
        this.fetchVillages({
          district_id: this.dataDistributor.alamat.kecamatan,
          pageNumber: 1,
          fields: {
            provinces: 'name',
          },
        });
      }

      if (this.dataDistributor.referal.no_upline) {
        this.searchReferal();
      }

      if (!registrationProduct && this.getMember.data.attributes.is_nd) {
        this.officeSearchForm.id = this.getMember.data.id
      }
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    closeModal() {
      this.success = this.failed = this.confirmation = false;
      this.clearData();
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataDistributor.alamat.provinsi,
        pageNumber: 1,
        fields: {
          // 'provinces': 'area_code,name'
        },
      });
      this.dataDistributor.alamat.kabupaten =
        this.dataDistributor.alamat.kecamatan =
        this.dataDistributor.alamat.desa =
          null;
      this.$store.commit('indonesia/setDistricts', this.objectAddress);
      this.$store.commit('indonesia/setVillages', this.objectAddress);
    },
    changeCity() {
      const city = this.getCities.data.find(
        (el) => el.id === this.dataDistributor.alamat.kabupaten
      );
      this.dataDistributor.area = city.attributes.area_code;
      this.fetchDistricts({
        city_id: this.dataDistributor.alamat.kabupaten,
        pageNumber: 1,
      });
      this.dataDistributor.alamat.kecamatan = this.dataDistributor.alamat.desa =
        null;
      this.$store.commit('indonesia/setVillages', this.objectAddress);
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataDistributor.alamat.kecamatan,
        pageNumber: 1,
      });
      this.dataDistributor.alamat.desa = null;
    },
    searchBonusPickup(q = null) {
      this.fetchOfficesByCategory({
        category: 'Stockist',
        params: {
          'pagination[type]': 'cursor',
          'page[limit]': 1,
          'filter[code]': q ?? this.dataDistributor.office_bonus_pickup.code,
          'fields[offices]': 'code,name',
        },
      }).then((response) => {
        if (!response.data[0]) {
          this.createAlert({
            data: 'Kantor tidak ditemukan',
            type: 'warning',
          });
        } else {
          const data = response.data[0];
          this.dataDistributor.office_bonus_pickup.id = data.id;
          this.dataDistributor.office_bonus_pickup.code = data.attributes.code;
          this.dataDistributor.office_bonus_pickup.name = data.attributes.name;
        }
      });
    },
    searchReferal(q = null) {
      this.fetchOfficesByCategory({
        category: 'Member',
        params: {
          'pagination[type]': 'cursor',
          'page[limit]': 1,
          'filter[is_draft]': false,
          'filter[is_active]': true,
          'filter[code]': q ?? this.dataDistributor.referal.no_upline,
          'fields[offices]': 'code,name',
        },
      }).then((response) => {
        if (!response.data[0]) {
          this.createAlert({
            data: 'Mitra tidak ditemukan',
            type: 'warning',
          });
          return;
        }
        const data = response.data[0];
        this.dataDistributor.referal.id = data.id;
        this.dataDistributor.referal.no_upline = data.attributes.code;
        this.dataDistributor.referal.nama_upline = data.attributes.name;
      });
    },
    addMember({ withValidation } = { withValidation: true }) {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            office_type: 'member',
            is_draft: withValidation ? false : true,
            office_registration_id: StorageService.getUser().office_id,
            office_referral_id: this.dataDistributor.referal.id,
            code: this.dataDistributor.nomor,
            name: this.dataDistributor.nama_distributor,
            pasif: null,
            urut: null,
            address: this.dataDistributor.alamat.detail,
            address2: null,
            // area: this.dataDistributor.area,
            kta: null,
            phone: this.dataDistributor.no_hp,
            email: this.dataDistributor.email,
            pulau: null,
            sex: this.dataDistributor.jenis_kelamin,
            marital_status: this.dataDistributor.status_pernikahan,
            npwp_number: this.dataDistributor.no_npwp,
            npwp_name: this.dataDistributor.nama_npwp,
            ktp_number: this.dataDistributor.no_nik,
            birthdate: this.dataDistributor.tanggal_lahir,
            spouse_name: this.dataDistributor.nama_pasangan,
            spouse_birthdate: this.dataDistributor.tanggal_pasangan,
            spouse_employment_status:
              this.dataDistributor.spouse_employment_status,
            devisor_name: this.dataDistributor.nama_pewaris,
            dependents_number: this.dataDistributor.dependents_number,
            devisor_birthdate: this.dataDistributor.tanggal_pewaris,
            is_suspended: false,
            bank_account_number: this.dataDistributor.bank.no_rekening,
            bank_account_name: this.dataDistributor.bank.nama_rekening,
            bank_name: this.dataDistributor.bank.nama_bank,
            bank_branch_name: this.dataDistributor.bank.cabang_bank,
            postal_code: this.dataDistributor.alamat.kode_pos,
            is_kta_need_delivery: this.dataDistributor.is_kta_need_delivery,
            address_details: [
              {
                province_id: this.dataDistributor.alamat.provinsi,
                city_id: this.dataDistributor.alamat.kabupaten,
                district_id: this.dataDistributor.alamat.kecamatan,
                village_id: this.dataDistributor.alamat.desa,
              },
            ],
          },
          relationships: {
            area: {
              data: {
                type: 'areas',
                id: this.getAreas.data.find(
                  (it) =>
                    it.attributes.code == parseInt(this.dataDistributor.area)
                ).id,
              },
            },
            'office-category': {
              data: {
                type: 'office-categories',
                id: this.member_office_category[0].id,
              },
            },
            'registration-product': {
              data: {
                type: 'products',
                id: this.dataDistributor.registration_product.id,
              },
            },
            ...(this.dataDistributor.office_bonus_pickup.id
              ? {
                  'bonus-office': {
                    data: {
                      type: 'offices',
                      id: this.dataDistributor.office_bonus_pickup.id,
                    },
                  },
                }
              : {}),
            ...(this.dataDistributor.education_id
              ? {
                  education: {
                    data: {
                      type: 'educations',
                      id: this.dataDistributor.education_id,
                    },
                  },
                }
              : {}),
            ...(this.dataDistributor.profession_id
              ? {
                  profession: {
                    data: {
                      type: 'professions',
                      id: this.dataDistributor.profession_id,
                    },
                  },
                }
              : {}),
            ...(this.dataDistributor.bank_id
              ? {
                  bank: {
                    data: {
                      type: 'banks',
                      id: this.dataDistributor.bank_id,
                    },
                  },
                }
              : {}),
          },
        },
      };
      if (!this.is_detail) {
        this.createMember(payload).then((response) => {
          if (!withValidation) {
            this.successMessage =
              'Berhasil membuat mitra, silahkan lakukan validasi terhadap mitra terlebih dahulu';
          }
          this.dataDistributor.id = response.data.data.id;
          response ? (this.success = true) : (this.failed = true);
        });
      } else {
        payload.data.id = this.dataDistributor.id;
        this.updateMember(payload).then((response) => {
          response ? (this.success = true) : (this.failed = true);
        });
      }
    },
    validateMember() {
      if (this.officeSearchForm.id) {
        this.saveAndValidate()
      } else {
        const payload = {
          data: {
            type: 'offices',
            id: this.dataDistributor.id,
            attributes: {
              is_draft: false,
              is_active: true,
            },
          },
        };
        this.updateMember(payload).then((response) => {
          this.confirmation = false;
          response ? (this.success = true) : (this.confirmation = false),
            (this.failed = true);
        });
      }
    },
    async saveAndValidate() {
      this.loadingSaveAndValidate = true

      const [, error] = await this.request(`/api/v1/offices/${this.officeSearchForm.id}/-actions/save-validate-member`, {
        method: 'post',
        data: {
          office_referral_id: this.dataDistributor.referal.id,
          name: this.dataDistributor.nama_distributor,
          address: this.dataDistributor.alamat.detail,
          phone: this.dataDistributor.no_hp,
          email: this.dataDistributor.email,
          sex: this.dataDistributor.jenis_kelamin,
          marital_status: this.dataDistributor.status_pernikahan,
          npwp_number: this.dataDistributor.no_npwp,
          npwp_name: this.dataDistributor.nama_npwp,
          ktp_number: this.dataDistributor.no_nik,
          birthdate: this.dataDistributor.tanggal_lahir,
          spouse_name: this.dataDistributor.nama_pasangan,
          spouse_birthdate: this.dataDistributor.tanggal_pasangan,
          spouse_employment_status:
            this.dataDistributor.spouse_employment_status,
          devisor_name: this.dataDistributor.nama_pewaris,
          dependents_number: this.dataDistributor.dependents_number,
          devisor_birthdate: this.dataDistributor.tanggal_pewaris,
          is_suspended: false,
          bank_account_number: this.dataDistributor.bank.no_rekening,
          bank_account_name: this.dataDistributor.bank.nama_rekening,
          bank_name: this.dataDistributor.bank.nama_bank,
          bank_branch_name: this.dataDistributor.bank.cabang_bank,
          postal_code: this.dataDistributor.alamat.kode_pos,
          is_kta_need_delivery: this.dataDistributor.is_kta_need_delivery,
          province_id: this.dataDistributor.alamat.provinsi,
          city_id: this.dataDistributor.alamat.kabupaten,
          district_id: this.dataDistributor.alamat.kecamatan,
          village_id: this.dataDistributor.alamat.desa,
          area_id: this.getAreas.data.find(
            (it) =>
              it.attributes.code == parseInt(this.dataDistributor.area)
          )?.id ?? null,
          registration_product_id: this.dataDistributor.registration_product.id,
          office_bonus_pickup_id: this.dataDistributor.office_bonus_pickup.id,
          education_id: this.dataDistributor.education_id,
          profession_id: this.dataDistributor.profession_id,
          bank_id: this.dataDistributor.bank_id,
        }
      })

      this.confirmation = false

      if (error) {
        this.createAlert({
          data: this.getRequestErrorMessage(error),
          status: 'error'
        })
      } else {
        this.success = true
      }

      this.loadingSaveAndValidate = false
    },
    saveAsDraft({ validate }) {
      if (validate()) {
        this.addMember({ withValidation: false });
      }
    },
    getMemberCode() {
      if (this.dataDistributor.registration_product.category !== 'DAFTAR') {
        return this.getMember?.data?.attributes?.temp_code ? `${this.getMember.data.attributes.code} / ${this.getMember.data.attributes.temp_code}` : this.getMember?.data?.attributes?.code
      }

      if (
        !this.getMember?.data?.attributes?.code ||
        !this.getMember?.data?.attributes?.temp_code
      ) {
        return '';
      }

      if (
        this.getMember?.data?.attributes?.code ==
        this.getMember?.data?.attributes?.temp_code
      ) {
        return this.getMember?.data?.attributes?.temp_code;
      }

      return `${this.getMember.data.attributes.code} / ${this.getMember.data.attributes.temp_code}`;
    },
    changeMitraCode(e) {
      const code = e.target.value.split('/')[0].trim();
      this.dataDistributor.nomor = code;
    },
    async removeOffice() {
      const payload = {
        data: {
          type: 'offices',
          id: this.$route.params.id,
          attributes: {
            deletedAt: new Date().toISOString(),
          },
        },
      };
      await this.updateOffice(payload);

      this.$router.push('/admin/daftar-member')
    },

    // Registration Product
    async checkProductStock(product) {
      const [res] = await this.request(
        `/api/v1/warehouses/${this.me.current_warehouse}/stocks`,
        {
          params: {
            'filter[product_code]': product.attributes.code,
            'fields[stocks]': 'stock_qty'
          },
        }
      );

      if (!res || !res.data || !res.data.length) {
        this.createAlert({
          data: `Produk KIT ${product.attributes.code} tidak memiliki stok tersedia`,
          status: 'error',
        });

        return false;
      }

      return res.data[0].attributes.stock_qty > 0;
    },
    async onChangeRegistrationProduct(product) {
      this.loadingRegistrationProduct = true;

      if (await this.checkProductStock(product)) {
        this.clearData()

        this.dataDistributor.registration_product.id = product.id;
        this.dataDistributor.registration_product.code =
          product.attributes.code;
        this.dataDistributor.registration_product.category =
          product.attributes.product_category_code;

        this.visibleRegistrationProductModal = false;
      }

      this.loadingRegistrationProduct = false;
    },
    async onSearchRegistrationProductCode() {
      this.loadingRegistrationProduct = true;

      const [res] = await this.request('/api/v1/products', {
        params: {
          'filter[is_for_registration]': true,
          'filter[code]': this.dataDistributor.registration_product.code,
          'fields[products]': 'code,product_category_code',
        },
      });

      if (res && res.data.length) {
        const product = res.data[0];

        this.clearData()

        if (await this.checkProductStock(product)) {
          this.dataDistributor.registration_product.id = product.id;
          this.dataDistributor.registration_product.code =
            product.attributes.code;
          this.dataDistributor.registration_product.category =
            product.attributes.product_category_code;
        } else {
          this.dataDistributor.registration_product.id = null;
          this.dataDistributor.registration_product.code = null;
          this.dataDistributor.registration_product.category = null
        }
      } else {
        this.createAlert({
          data: `Produk KIT ${this.dataDistributor.registration_product.code} tidak ditemukan`,
          status: 'error',
        });

        this.dataDistributor.registration_product.id = null;
        this.dataDistributor.registration_product.code = null;
        this.dataDistributor.registration_product.category = null
      }

      this.loadingRegistrationProduct = false;
    },

    // Office Search (Mitra Usaha ND)
    async onSearchOffice() {
      this.loadingSeachOffice = true

      const [res] = await this.request('/api/v1/offices/-actions/update-register-by', {
        method: 'patch',
        data: {
          code: this.officeSearchForm.code,
          pin: this.officeSearchForm.pin
        }
      })

      if (res) {
        this.officeSearchForm.id = res.data.id
        
        const office = await this.loadMember(res.data.id)

        this.setMember(office.data.data);
      } else {
        this.createAlert({
          data: `Kode Mitra / PIN Usaha Tidak Ditemukan`,
          status: 'error',
        });
      }

      this.loadingSeachOffice = false
    }
  },
};
</script>
